import Vue from 'vue'
import Router from 'vue-router'
import Login from './views/Login.vue'
import EscolheAreas from './views/EscolheAreas.vue'
import EscolheVaras from './views/EscolheVaras.vue'
import EscolheSubsecoes from './views/EscolheSubsecoes.vue'
import ConfirmaDadosInscricao from './views/ConfirmaDadosInscricao.vue'
import ConfirmaDadosInscricaoEleitoral from './views/ConfirmaDadosInscricaoEleitoral.vue'
import ConfirmaDadosInscrito from './views/ConfirmaDadosInscrito.vue'
import ConfirmaDadosInscritoEleitoral from './views/ConfirmaDadosInscritoEleitoral.vue'
import EscolheOpcao from './views/EscolheOpcao.vue'
import EscolhePlantonista from './views/EscolhePlantonista.vue'
import EscolhePlantonistaEleitoral from './views/EscolhePlantonistaEleitoral.vue'
import InscricaoFinalizada from './views/InscricaoFinalizada.vue'
import InscricaoFinalizadaEleitoral from './views/InscricaoFinalizadaEleitoral.vue'

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      name: 'login',
      component: Login
    },
    {
      path: '/escolhe-opcao',
      name: 'escolhe-opcao',
      component: EscolheOpcao
    },
    {
      path: '/escolhe-areas',
      name: 'escolhe-areas',
      component: EscolheAreas
    },
    {
      path: '/escolhe-varas',
      name: 'escolhe-varas',
      component: EscolheVaras
    },
    {
      path: '/escolhe-subsecoes',
      name: 'escolhe-subsecoes',
      component: EscolheSubsecoes
    },
    {
      path: '/inscricao-finalizada',
      name: 'inscricao-finalizada',
      component: InscricaoFinalizada
    },
    {
      path: '/inscricao-finalizada-eleitoral',
      name: 'inscricao-finalizada-eleitoral',
      component: InscricaoFinalizadaEleitoral
    },
    {
      path: '/escolhe-plantonista',
      name: 'escolhe-plantonista',
      component: EscolhePlantonista
    },
    {
      path: '/escolhe-plantonista-eleitoral',
      name: 'escolhe-plantonista-eleitoral',
      component: EscolhePlantonistaEleitoral
    },
    {
      path: '/confirma-dados-inscricao',
      name: 'confirma-dados-inscricao',
      component: ConfirmaDadosInscricao
    },
    {
      path: '/confirma-dados-inscricao-eleitoral',
      name: 'confirma-dados-inscricao-eleitoral',
      component: ConfirmaDadosInscricaoEleitoral
    },
    {
      path: '/confirma-dados-inscrito',
      name: 'confirma-dados-inscrito',
      component: ConfirmaDadosInscrito
    },
    {
      path: '/confirma-dados-inscrito-eleitoral',
      name: 'confirma-dados-inscrito-eleitoral',
      component: ConfirmaDadosInscritoEleitoral
    }
  ]
})
