<template>
	<div class="escolheAreas">
		<div class="box-area">
			<div class="logo"></div>
			<alert-message/>
			<div class="info active">
				<strong>Sua inscrição foi realizada!</strong>
				<br>
				<!--br>
				<strong>Nº da sua inscrição: undefined</strong-->
				<br>
				<br>Você receberá o comprovante de inscrição por e-mail em breve.
				<br><br>Clique abaixo para conferir seus dados e ver as suas inscrições ou, realizar uma nova.
			</div>
            <div class="area-content">
				
			</div>

			<div class="opcoes-content">
				<div class="button">
					<button @click="$router.push('/confirma-dados-inscrito')">Conferir dados de inscrição</button>
					<button  @click="$router.push('/escolhe-areas')">Realizar nova inscrição</button>	
					<!-- <button  @click="$router.push('/escolhe-varas')">Realizar nova inscrição</button>	 -->
				</div>
			</div>

			
		</div>
	</div>
</template>

<script>
import AlertMessage from './components/AlertMessage.vue';
	export default {
		name: "escolhe-plantonista",
		components: {AlertMessage},
		data() {
			return {
				aviso_teste: false,
				valida_token_url: this.$store.state.url_api + "/verificaTokenValido.php"
			};
		},
		methods: {
			verificaTokenValido(token) {
				return new Promise((resolve, reject) => {
					const Http = new XMLHttpRequest();
					const url = this.valida_token_url;

					let data = new FormData();
					data.append('token', token);

					Http.open("POST", url);
					Http.send(data);
					Http.onreadystatechange = (e) => {
						if (Http.readyState == 4 && Http.status == 200) {
							var res = Http.responseText;
							res = JSON.parse(res);
							if(res.mode) {
								if(res.mode === "sandbox") {
									this.aviso_teste = true;
								}
							}
							if (res.tokenValido) {
								resolve(true);
							} else {
								resolve(false);
							}
						}
					}
				});
			}
        },
		beforeMount() {
			let _self = this;
			this.$emit("loader", { active: true });
			_self.$store.commit('get_storage');
			if(sessionStorage.token){
				this.verificaTokenValido(sessionStorage.getItem('token')).then((verificaToken) => {
					if(!verificaToken){
						sessionStorage.removeItem('token');
						sessionStorage.removeItem('varas');
						sessionStorage.removeItem('tipos_escolhidos');
						_self.$emit('alert_called', {message: 'Token de sessão inválido!', type: 'danger'});
						_self.$router.push('/');
					}
				});
				
			}else{
				sessionStorage.removeItem('token');
				sessionStorage.removeItem('varas');
				sessionStorage.removeItem('tipos_escolhidos');
				_self.$emit('alert_called', {message: 'Token de sessão inválido!', type: 'danger'});
				this.$router.push('/');
			}
			this.$emit("loader", { active: false });
			
		}
	};
</script>
<style lang="scss" scoped>
	.escolheAreas {
		background-image: url("https://advogadosoab.arcoinformatica.com.br/view/public/img/bkg-vitoria.jpg");
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: #ddd;
		display: flex;
		align-items: center;
		justify-content: center;
		background-size: cover;
		overflow-y: auto;

		@media (max-width: 480px) {
			.box-area {
				width: 90%;
			}

			.opcoes-content {
				flex-wrap: wrap;

				.button {
					width: 100% !important;
					flex-grow: 1 !important;
				}
			}
		}

		@media (max-width: 768px) {
			.box-area {
				width: 60%;
			}
		}

		@media (min-width: 768px) {
			.box-area {
				width: 440px;
			}
		}

		.box-area {
			background-color: white;
			padding: 50px;
			box-shadow: 0 0 30px #717171;

			&::before {
				content: "";
				position: fixed;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				width: 100%;
				height: 100%;
				background-color: rgba(0, 0, 0, 0.4);
				z-index: -1;
				pointer-events: none;
				margin: 0;
			}

			.logo {
				background-image: url("https://www.oabes.org.br/img/logo/logo.png");
				width: 100%;
				padding-bottom: 15%;
				background-size: contain;
				background-position: left;
				background-repeat: no-repeat;
			}

			.info {
				font-size: 14px;
				text-align: left;
				width: 100%;
				padding-top: 30px;
				display: none;

				.info-senha {
					span {
						color: #737373;
						line-height: 20px;
					}
				}

				&.active {
					display: block;
				}
			}

			.button {
				// width: 100%;
				display: flex;
                flex-wrap: wrap;

				button {
					padding: 10px 30px;
					border: 0;
					background-color: #357a9b;
					color: white;
					font-size: 14px;
					cursor: pointer;
                    flex: 1;
                    margin-bottom: 20px;

					&:hover {
						background-color: #0a435f;
						transition: 0.3s all ease-in-out;
					}
				}

                button:first-child {
                    margin-right: 20px;
                }
			}

			.area-content {
				width: 100;
				margin-top: 30px;

				.area-box {
					width: 100%;
					padding: 10px;
					background-color: #eee;
					border-bottom: solid 1.3px #dadada;
					border-right: solid 1.3px #dadada;
					margin-bottom: 15px;
					cursor: pointer;
					display: flex;
					align-items: center;
					justify-content: flex-start;
					transition: 0.1s ease-in-out all;

					&:hover {
						transform: scale(1.02);
					}

					&.active {
						background-color: #43a047;

						.description {
							color: white;
						}

						.check {
							border-color: white;

							&::after {
								content: "\2713";
								position: absolute;
								top: -50%;
								left: 50%;
								bottom: 0;
								right: 0;
								width: 100%;
								height: 100%;
								font-size: 53px;
								display: flex;
								align-items: center;
								justify-content: center;
								color: #183e00;
							}
						}
					}

					.check {
						width: 17px;
						height: 17px;
						border: solid 3px #929292;
						border-radius: 3px;
						margin-right: 15px;
						position: relative;
					}

					.description {
						text-transform: uppercase;
						font-weight: bold;
						color: #565656;
					}
				}
			}
		}
	}

	.opcoes-content {
		display: flex;
		justify-content: flex-end;
	}

	@media (max-width: 480px) {
		.box-area {
			width: 90%;
		}

		.opcoes-content {
			flex-wrap: wrap;

			.button {
				width: 100% !important;
				flex-grow: 1 !important;
			}
		}
	}

    @media (max-width: 330px) {
        .escolheAreas .box-area .button button:first-child {
            margin-right: 0;
        }
    }
</style>