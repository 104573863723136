<template>
  <div id="app">
    <!-- <div id="nav">
      <router-link to="/">Hodsme</router-link> |
      <router-link to="/about">About</router-link>
    </div> -->
    <transition name="fade">
    <router-view v-on:loader="loader($event)" v-on:alert_called="show_alert($event)" />
    </transition>
    <div class="alert-content">
      <div :class="{alert:true, active:alert.active, danger: (alert.type == 'danger'), success: (alert.type == 'success')}" >
        {{alert.message}}
      </div>
    </div>
    <div :class="{'loader-content': true, active: loader_active}">
      <div class="loading"></div>
    </div>
    
  </div>
</template>
<script>
	export default {
		name: "app",
		components: {},
		data() {
			return {
        alert: {
          active: false,
          type: 'danger',
          message: 'teste de mensagem'
        },
				loader_active:false
        
			}
		},
		methods: {
      show_alert(data){
        window.clearTimeout();
        this.alert.message = data.message;
        this.alert.type = data.type;
        this.alert.active = true;
        let _self = this;
        setTimeout(function(){
          _self.alert.active = false;
        }, 4000);
      },
      loader(event){
        this.loader_active = event.active;
      }
    },
	};
</script>


<style lang="scss" >
#app {
 font-family: 'Montserrat', sans-serif;;

}
#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .7s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active em versões anteriores a 2.1.8 */ {
  opacity: 0;
}
.alert-content{
  position: fixed;
  bottom: 5%;
  width: 100%;
  display: flex;
  justify-content: center;
  pointer-events: none;
    .alert{
    padding: 20px;
    box-shadow: 0 0 30px #717171;
    font-size: 16px;
    color:white;
    max-width: 96%;
    transform: translateY(70px);
    opacity: 0;
    pointer-events: none;
    transition: .3s ease-in-out all;


    &.active{
      transform: translateY(0);
      opacity: 0.9;
    }

    &.danger{
      background-color:#d50000;
    }
    &.success{
      background-color:#33691e;
    }
  }
}
.loader-content{
  position: fixed;
  z-index: 999999;
  background-color: rgba(0,0,0,0.5);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  opacity: 0;
  transition: .2s all ease-in-out;

  &.active{
    opacity: 1;
  }

  .loading{
    display: inline-block;
    width: 50px;
    height: 50px;
    border: 3px solid rgba(255,255,255,.3);
    border-radius: 50%;
    border-top-color: #fff;
    animation: spin 1s ease-in-out infinite;
    -webkit-animation: spin 1s ease-in-out infinite;
  }
}

@keyframes spin {
  to { -webkit-transform: rotate(360deg); }
}
@-webkit-keyframes spin {
  to { -webkit-transform: rotate(360deg); }
}

@media (max-width:480px){
  .box-login{
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: auto !important;
    z-index: 9999;
    &::before{
      opacity: 0;
    }
  }
  .box-area{
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: auto !important;
    z-index: 9999;
    padding-left: 15px;
    padding-right: 15px;

    .area-content{
      width: auto !important;
    }
    &::before{
      opacity: 0;
    }
  }

  
}
.alert-teste-content{
  background-color: #ffc3c3;
  color: red;
  padding: 15px;
  font-size: 14px; 
  margin-top: 20px;
  border:solid 2px #ffa7a7;

  b{
    font-weight: bold;
  }
}

</style>
