<template>
	<div class="escolheComarcas">
		<div class="box-comarca">
			<div class="logo"></div>
			<alert-message/>
			<div class="info active">
				<strong>Confirme os dados da sua inscrição</strong> <br /><br />
				Confirme seus dados abaixo antes de finalizar a inscrição.
			</div>

			<div class="box-info">
				<div class="title-area">
					Dados Pessoais
				</div>
				<div class="field" v-for="dado in dados_pessoais">
					<div class="label">
						{{dado.label}}
					</div>
					<div class="value">
						{{dado.value}}
					</div>
				</div>
				<div class="aviso">
					Os seus dados pessoais listados acima poderão ser alterados depois da inscrição.

				</div>
			</div>

			<div class="box-info">
				<div class="title-area">
					Varas Selecionadas
				</div>
				<div class="field" v-if="comarca.ativo" v-for="comarca in varas">
					<div class="comarca">
						{{comarca.comarca}}
					</div>
					
					<div class="vara" v-if="vara.ativo" v-for="vara in comarca.varas">
						<span>〉</span> {{vara.nome_vara}}
					</div>
				</div>
			</div>
			<div class="area-content">
				<div v-for="tipo in termos" v-bind:key="tipo.id" :class="{'area-box':true,active: tipo.ativo}">
					<div v-on:click="togglerTipo(tipo.id);" class="check">

					</div>
					<div v-on:click="abrirModal(tipo.id);" class="description" v-html="tipo.nome">

					</div>
				</div>
			</div>



			<div class="button">
				<button v-if="!button_hidden" v-on:click="finalizaInscricao();">Finalizar Inscrição</button>
			</div>

		


			<div v-show="termos[1].modal" id="myModal" class="modal">
				<div class="modal-content" style="max-height: 381px; overflow-y: scroll;max-width: 640px;" @scroll="handleScroll">
					<span  v-on:click="fecharModal(2)" class="close">&times;</span>
					<h2>TERMO DE COMPROMISSO</h2>
					No exercício do múnus de advogado(a) dativo ao qual me inscrevi na Ordem dos</br></br>
					Advogados do Brasil – Seção do Estado do Espírito Santo, me comprometo durante</br>
					todo o lapso temporal pelo qual permanecer inscrito(a) a:</br></br>
					a) Realizar a assistência judiciária como defensor(a) dativo apenas nos casos em que o assistido restar comprovada sua hipossuficiência financeira nos autos do processo judicial através de inscrição no Cadastro Único para Programas Sociais do Governo Federal (Cadastro Único), de modo a não desenvolver qualquer exercício profissional como advogado(a) dativo em quaisquer hipóteses que contrariem o exposto, sob pena de responder	processo no Tribunal de Ética e Disciplina da OAB/ES;
					</br>
					</br>
					b) Prestar os serviços a que me proponho de maneira adequada e em conformidade com as normas de conduta previstas em legislação, especialmente no Código de Ética e Disciplina da OAB, sob pena de responder processo no Tribunal de Ética e Disciplina da OAB/ES;
					</br>
					</br>
					c) Não me negar ou me opor a comparecer ou realizar atendimento quando convocado pelo Magistrado perante as unidades judiciárias, exceto em se tratando de necessidade comprovadamente justificada, sob pena de responder processo no Tribunal de Ética e Disciplina da OAB/ES;
					</br>
					</br>
					E por ser a expressão da verdade, aceito o presente, para que surta seus efeitoslegais e jurídicos.
					<span  v-on:click="fecharModal(2)" class="close">Clique aqui para fechar</span>
				</div>
			</div>
			<div v-show="termos[2].modal" id="myModal" class="modal">
				<div class="modal-content" style="max-height: 480px; overflow-y: scroll;max-width: 640px;" @scroll="handleScroll">
					<span  v-on:click="fecharModal(3)" class="close">&times;</span>
					<h2>POLÍTICA DE PRIVACIDADE</h2>
					<p>Esta política de privacidade cumpre as exigências da Lei n. 13.709/2018 – Lei Geral de Proteção de Dados Pessoais – LGPD, quanto ao procedimento denominado “Inscrição dativos 2022” organizado pelos seguintes agentes de tratamento de dados pessoais: Seccional do Espírito Santo da Ordem dos Advogados do Brasil - OAB/ES, denominada desde já como operador de dados pessoais e o Tribunal de Justiça do Estado do Espírito Santo – TJES, denominada desde já controladora dos dados pessoais.</p>
					<p>Este procedimento de tratamento de dados pessoais nasce da inscrição entre os agentes de tratamento de dados pessoais com a finalidade específica contida no art.7º, inc. III (pela administração pública, para o tratamento e uso compartilhado de dados necessários à execução de políticas públicas previstas em leis e regulamentos ou respaldadas em contratos, convênios ou instrumentos congêneres)  e art. 26 da LGPD, respeitando ainda a Lei de Acesso a Informações – LAI (Lei n.12.527/2011) e também art.7º, inc.V da LGPD (quando necessário para a execução de contrato ou de procedimentos preliminares relacionados a contrato do qual seja parte o titular, a pedido do titular dos dados) . Tal tratamento está em conformidade com todos os princípios e fundamentos descritos nos arts. 6º e 2º da LGPD.</p>
					<p>Durante este tratamento de dados pessoais o operador de dados pessoais irá coletar dos titulares de dados pessoais, advogados, devidamente inscritos em seu quadro e compartilhar com o controlador os seguintes dados pessoais:</p>
					<p>1. Nome completo;</p>
					<p>2. Número de inscrição na Ordem dos Advogados do Brasil, Seccional Espírito Santo;</p>
					<p>3. Endereço completo:</p>
					<p>4. Número de telefones fixo e/ou celular;</p>
					<p>5. E-mail</p>
					<p>O operador, após o período previsto em edital para coleta e classificação dos dados pessoais, compartilhará com o controlador os dados pessoais tratados, não mantendo nenhum banco de dados de tratamento de dados pessoais relacionados com esta política de privacidade, ficando sob a exclusiva responsabilidade do controlador o tratamento de dados pessoais pelo período de 12 (doze) meses, ou até que se faça novo edital para a mesma finalidade.</p>
					<p>Os agentes de dados poderão vir a manter seus dados pessoais caso seja necessário para cumprimento de obrigações legais, resolver disputas, evitar fraudes e abuso e garantir o cumprimento de contratos.</p>
					<p>Os titulares de dados pessoais podem exercer todos os direitos descritos na LGPD, nos seus arts. 17 e segs., através de e-mails que podem ser enviados aos encarregados pelo tratamento de dados pessoais (DPO) dos agentes de tratamento: dpo@oabes.org.br.</p>
					<p>Esta política está sujeita à Lei da República Federativa do Brasil e o Foro da Comarca de Vitória, ES é competente para dirimir qualquer controvérsia com relação à mesma.</p>
					<span  v-on:click="fecharModal(3)" class="close">Clique aqui para fechar</span>
				</div>
			</div>

		</div>
	</div>	
</template>

<script>
import AlertMessage from './components/AlertMessage.vue';
	export default {
		name: "confirma-dados-inscricao",
		components: {AlertMessage},
		data() {
			return {
				url_varas: this.$store.state.url_api + '/getVaras.php',
				url_inscricao: this.$store.state.url_api + '/realizaInscricao.php',
				valida_token_url: this.$store.state.url_api + '/verificaTokenValido.php',
				url_dados_pessoais: this.$store.state.url_api + '/getAdvogado.php',
				varas: {},
				aviso_teste: false,
				varas_selecionadas: [],
				button_hidden: false,
				modal_scroll_100: false,
				termos: [
					{
						id: 1,
						ativo: false,
						lido: true,
						modal: false,
						nome: 'Li e concordo da <span style="color:#35789a;cursor:pointer"><a style="color:unset;text-decoration: none !important;" target="_blank" href="http://www.tjes.jus.br/corregedoria/2018/10/22/resolucao-no-032-2018-disp-22-10-2018/">Resolução 32/2018</a></span>'
					},
					// {
					// 	id: 2,
					// 	ativo: false,
					// 	lido: true,
					// 	modal: false,
					// 	nome: 'Li e concordo da <span style="color:#35789a;cursor:pointer"><a style="color:unset;text-decoration: none !important;" target="_blank" href="https://legislacao.presidencia.gov.br/atos/?tipo=LEI&numero=1060&ano=1950&ato=593MTVU1UMBRVT8dc">Lei 1060/50</a></span>'
					// },
					{
						id: 2,
						ativo: false,
						lido: false,
						modal: false,
						nome: 'Li e concordo com os <span style="color:#35789a;cursor:pointer">Termos de Compromisso</span>'
					},
					{
						id: 3,
						ativo: false,
						lido: false,
						modal: false,
						nome: 'Li e concordo com os termos da <span style="color:#35789a;cursor:pointer">Política de Privacidade</span>'
					}
				],
				//dados pessoais
				dados_pessoais: {
					'num_oab': {
						label: 'Número da OAB',
						value: sessionStorage.getItem('num_oab')
					},
					'nome': {
						label: 'Nome',
						value: ''
					},
					'cpf': {
						label: 'CPF',
						value: ''
					},
					'endereco': {
						label: 'Endereço Profissional',
						value: ''
					},
					'tel_fixo': {
						label: 'Telefone Fixo',
						value: ''
					},
					'tel_celular': {
						label: 'Telefone Celular',
						value: ''
					},
					'email': {
						label: 'E-mail',
						value: ''
					}
				},
			}
		},
		created(){
			this.$store.commit('get_storage');
			this.$store.state.varas.forEach((comarca)=>{
				console.log(comarca);
			})
		},
		methods: {
		    handleScroll: function(el) {
				if((el.srcElement.offsetHeight + el.srcElement.scrollTop) >= el.srcElement.scrollHeight) {
					const _self = this;
					_self.modal_scroll_100 = true
				}
			},
			togglerTipo(id) {
				const _self = this;
				if(_self.termos[id-1].lido) _self.termos[id-1].ativo = !_self.termos[id-1].ativo
				else this.abrirModal(id)
			},
			abrirModal(id) {
				const _self = this;
				_self.termos[id-1].modal = true
			},
			fecharModal(id) {
				const _self = this;
				if(_self.modal_scroll_100 === true) {
					_self.modal_scroll_100 = false
					_self.termos[id-1].modal = false;
					_self.termos[id-1].lido = true
				}else{
					alert("Role até o final antes de fechar")
				}
			},
			finalizaInscricao() {
				this.$emit('loader', { active:true });
				const _self = this;
				let token_inscricao = this.$store.state.token;
				let max_tentativas = 5;
				let tentativas = 0;
				this.button_hidden = true;
				this.loader_active = true;
				if (
					!this.termos[0].ativo ||
					!this.termos[1].ativo ||
					!this.termos[2].ativo 
					// !this.termos[3].ativo
				) {
					_self.button_hidden = false;
					_self.$emit('alert_called', { message: 'Para continuar, é necessário marcar todos os termos de ciência.', type: 'danger' });
					_self.$emit('loader', { active:false });
					
				} else {
					let response = _self.requestInscricao(token_inscricao, tentativas);
				}
			},
			requestInscricao(token, tentativas) {

				const Http = new XMLHttpRequest();
				const url = this.url_inscricao;
				const _self = this;
				tentativas++;

				//tipos escolhidos
				let tipos_escolhidos = _self.$store.state.tipos_escolhidos;

				let plantonista = false;
				let juri = false;
				console.log("LS:" + _self.$store.state.plantonista)
				console.log("JURI: "+ _self.$store.state.juri)
				if(_self.$store.state.plantonista == "true") plantonista = true;
				if(_self.$store.state.juri == "true") juri = true;
				console.log("variavel:" + plantonista)

				//formata varas
				let varas_escolhidas = [];
				console.log("VISUALIZANDO STORE VARAS");
				console.log(_self.$store.state.varas);
				_self.$store.state.varas.forEach((comarca) => {
					
					comarca.varas.forEach((vara) => {
						if(vara.ativo){
							console.log(vara);
							console.log("Verificando o store");
							console.log(_self.$store.state.juri);
							console.log(typeof _self.$store.state.juri);
							
							varas_escolhidas.push(vara);
							// varas_escolhidas.push(vara.id_vara);
						}
					});
				});
				console.log("verificando as varas escolhidas");
				console.log(varas_escolhidas);

				var data = new FormData();
				data.append('token', token);
				data.append('varas', JSON.stringify(varas_escolhidas));
				data.append('tipos', tipos_escolhidos);
				// data.append('plantonista', plantonista);
				data.append('juri', juri);

				Http.open("POST", url);
				Http.send(data);
				Http.onreadystatechange = (e) => {
					if (Http.readyState == 4) {
						if (Http.status == 200) {
							// _self.requestInscricao(token, tentativas, request_success);
							let res = Http.responseText;
							res = JSON.parse(res);

							//verifica se a inscrição foi realizada
							if(res.inscricaoRealizada){

								//alerta de inscrição realizada
								_self.$store.commit('clear_all');
								
								_self.$emit('alert_called', { message: "Inscrição realizada com sucesso!", type: 'success' });
								this.$emit('loader', { active:false });
								_self.$router.push('/inscricao-finalizada');
								
							}else{
								//alerta de inscrição não realizada
								_self.errorMap(res.errorCode);
								_self.$emit('loader', { active:false });
							}
						} else {
							//se a requisição não teve status 200, tentar de novo
							if (tentativas <= 3) {
								setTimeout(() => {
									_self.requestInscricao(token, tentativas);
								}, 1000);
							}else{
								_self.button_hidden = false;
								this.$emit('loader', { active:false });
								_self.$emit('alert_called', { message: "Parece que há muitas pessoas tentando realizar inscrições ao mesmo tempo, tente novamente.", type: 'danger' });
								
							}
						}
					}
				}
			},
			errorMap(code){  
				let msg = '';
				switch (code) {
					case 1:
						msg = 'Token de sessão inválido. Será necessário realizar a inscrição novamente';
						this.$emit('alert_called', { message: msg, type: 'danger' });
						this.$router.push('/');
						break;
					
					case 2:
						msg = 'Tipo de atuação inválido. É necessário selecionar os tipos e as varas novamente.';
						this.$emit('alert_called', { message: msg, type: 'danger' });
						this.$router.push('/escolhe-varas');
						break;
					
					case 3:
						msg = 'Existe uma vara inválida na listagem. Por favor, selecione as varas novamente.';
						this.$emit('alert_called', { message: msg, type: 'danger' });
						this.$router.push('/escolhe-varas');
						break;
					
					case 4:
						msg = 'Não foi possível inserir as varas escolhidas. Por favor, selecione as varas novamente.';
						this.$emit('alert_called', { message: msg, type: 'danger' });
						this.$router.push('/escolhe-varas');
						break;

					case 11:
						msg = 'As inscrições ainda não foram abertas.';
						this.$emit('alert_called', { message: msg, type: 'danger' });
						this.$router.push('/escolhe-varas');
						break;

					case 12:
						msg = 'As inscrições foram encerradas.';
						this.$emit('alert_called', { message: msg, type: 'danger' });
						this.$router.push('/escolhe-varas');
						break;


					case 13:
						msg = 'Não foi possível realizar sua inscrição. Selecione apenas uma comarca por inscrição.';
						this.$emit('alert_called', { message: msg, type: 'danger' });
						this.$router.push('/escolhe-varas');
						break;

					case 14:
						msg = 'Não foi possível realizar sua inscrição, favor realizar contato com os serviços da OAB-ES';
						this.$emit('alert_called', { message: msg, type: 'danger' });
						this.$router.push('/escolhe-varas');
						break;
					
					case 99:
						msg = 'Ocorreu um erro interno do sistema. Tente novamente mais tarde.';
						this.$emit('alert_called', { message: msg, type: 'danger' });
						this.$router.push('/escolhe-varas');
						break;
				
					default:
						break;
				}
			},
			verificaTokenValido(token) {
				return new Promise((resolve, reject) => {
					const Http = new XMLHttpRequest();
					const url = this.valida_token_url;

					let data = new FormData();
					data.append('token', token);

					Http.open("POST", url);
					Http.send(data);
					Http.onreadystatechange = (e) => {
						if (Http.readyState == 4 && Http.status == 200) {
							var res = Http.responseText;
							res = JSON.parse(res);
							if(res.mode) {
								if(res.mode === "sandbox") {
									this.aviso_teste = true;
								}
							}
							if (res.tokenValido) {
								resolve(true);
							} else {
								resolve(false);
							}
						}
					}
				});
			}
		},
		beforeMount() {
			const _self = this;
			this.$emit('loader', { active:false });

			if(sessionStorage.token){
				this.verificaTokenValido(sessionStorage.getItem('token')).then((verificaToken) => {
					if(!verificaToken){
						sessionStorage.removeItem('token');
						sessionStorage.removeItem('varas');
						sessionStorage.removeItem('tipos_escolhidos');
						_self.$emit('alert_called', {message: 'Token de sessão inválido!', type: 'danger'});
						_self.$router.push('/');
					}
				});
				
		}else{
				sessionStorage.removeItem('token');
				sessionStorage.removeItem('varas');
				sessionStorage.removeItem('tipos_escolhidos');
				_self.$emit('alert_called', {message: 'Token de sessão inválido!', type: 'danger'});
				this.$router.push('/');
			}

			//atualiza com dados do storage
			_self.$store.commit('get_storage');

            //tratamento de voltar
			if(sessionStorage.tipos_escolhidos == "" || _self.$store.varas == "") {
				this.$router.push('/escolhe-areas') 
				return false;
			} 

			// busca varas selecionadas
			const varas = this.$store.state.varas;
			varas.forEach(function (comarca) {
				comarca.ativo = false;
				comarca.varas.forEach(function (vara) {
					if (vara.ativo) {
						comarca.ativo = true;
					}
				});
			});
			this.varas = varas;

			//busca dados pessoais do advogado
			//busca dados pessoais
			

			//busca dados pessoais
			const Http = new XMLHttpRequest();
			const url = this.url_dados_pessoais;


			let data = new FormData();
			data.append('token', _self.$store.state.token);

			Http.open("POST", url);
			Http.send(data);
			Http.onreadystatechange = (e) => {
				if (Http.readyState == 4 && Http.status == 200) {
					var res = Http.responseText;
					res = JSON.parse(res);
					// console.log(res);
					_self.dados_pessoais.nome.value = res.nome_completo;
					_self.dados_pessoais.num_oab.value = res.oab;
					_self.dados_pessoais.cpf.value = res.cpf;
					_self.dados_pessoais.endereco.value = res.endereco;
					_self.dados_pessoais.tel_fixo.value = res.telefone_fixo;
					_self.dados_pessoais.tel_celular.value = res.telefone_celular;
					_self.dados_pessoais.email.value = res.email;
					this.$emit('loader', { active:false });
				}
			}

		}
	};
	
</script>
<style lang="scss" scoped>
	.escolheComarcas {
		background-image: url('https://advogadosoab.arcoinformatica.com.br/view/public/img/bkg-vitoria.jpg');
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: #ddd;
		display: flex;
		align-items: center;
		justify-content: center;
		background-size: cover;
		// overflow-y: auto;


		@media (max-width: 480px) {
			.box-comarca {
				width: 100% !important;
				height: 100% !important;
				max-height: calc(100% - 60px);
				padding-left: 15px !important;
				padding-right: 15px !important;
			}

			.area-box {
				width: auto !important;

			}

			.value {
				word-break: break-word;
			}
		}

		@media (max-width: 768px) {
			.box-comarca {
				width: 60%;
			}
		}

		@media (min-width: 768px) {
			.box-comarca {
				width: 600px;
				max-height: calc(90% - 60px);
			}
		}

		.box-comarca {
			background-color: white;
			padding: 50px;
			box-shadow: 0 0 30px #717171;
			overflow-y: auto;

			&::before {
				content: '';
				position: fixed;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				width: 100%;
				height: 100%;
				background-color: rgba(0, 0, 0, 0.4);
				z-index: -1;
				pointer-events: none;
				margin: 0;
			}

			.logo {
				background-image: url('https://www.oabes.org.br/img/logo/logo.png');
				width: 100%;
				padding-bottom: 15%;
				background-size: contain;
				background-position: left;
				background-repeat: no-repeat;
			}

			.info {
				font-size: 14px;
				text-align: left;
				width: 100%;
				padding-top: 30px;
				display: none;

				.info-senha {
					span {
						color: #737373;
						line-height: 20px;
					}
				}

				&.active {
					display: block;
				}
			}

			.button {
				margin-top: 30px;
				width: 100%;
				display: flex;
				justify-content: flex-end;

				button {
					padding: 10px 30px;
					border: 0;
					background-color: #357a9b;
					color: white;
					font-size: 14px;
					cursor: pointer;

					&:hover {
						background-color: #0a435f;
						transition: .3s all ease-in-out;
					}
				}
			}
		}
	}

	.box-info {
		margin-bottom: 30px;
		box-shadow: 0px 2px 2px #d8d8d8;
		background-color: #f8f8f8;
		padding: 10px;
		margin-top: 15px;

		.title-area {
			font-weight: bold;
			color: #616161;
			padding-bottom: 15px;
			font-size: 20px;
		}

		.field {
			margin-bottom: 15px;

			.label {
				font-weight: bold;
				text-transform: uppercase;
				font-size: 14px;
				color: #333;
			}

			.comarca {
				font-weight: bold;
				text-transform: uppercase;
				font-size: 14px;
				color: #333;
				padding-bottom: 10px;
			}

			.vara {
				padding-left: 15px;

				span {
					font-size: 12px;
				}
			}

			.value {
				padding: 10px;
				background-color: #dcdcdc;
				color: #333;
				margin-top: 3px;
			}
		}
	}

	.area-content {
		width: 100;
		margin-top: 30px;

		.area-box {
			width: 100%;
			padding: 10px;
			background-color: #eee;
			border-bottom: solid 1.3px #dadada;
			border-right: solid 1.3px #dadada;
			margin-bottom: 15px;
			// cursor: pointer;
			display: flex;
			align-items: center;
			justify-content: flex-start;
			transition: 0.1s ease-in-out all;



			&:hover {
				// transform: scale(1.02);
			}

			&.active {
				background-color: #43a047;

				.description {
					color: white;
				}

				.check {
					border-color: white;


					&::after {
						content: "\2713";
						position: absolute;
						top: -50%;
						left: 50%;
						bottom: 0;
						right: 0;
						width: 100%;
						height: 100%;
						font-size: 53px;
						display: flex;
						align-items: center;
						justify-content: center;
						color: #183e00;
						cursor: pointer;
					}
				}

			}

			.check {
				width: 17px;
				height: 17px;
				border: solid 3px #929292;
				border-radius: 3px;
				margin-right: 15px;
				position: relative;
				cursor: pointer;



			}

			.description {
				text-transform: none;
				font-weight: bold;
				color: #565656;
				font-size: 16px;
			}
		}
	}

	.aviso {
		background-color: #c8eeff;
		padding: 10px;
		text-align: center;
		color: #005b82;
		font-weight: bold;
	}
	/* The Modal (background) */
.modal {  
  position: fixed; /* Stay in place */
  z-index: 999; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

/* Modal Content */
.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
}

/* The Close Button */
.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

</style>