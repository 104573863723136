<template>
	<div class="escolheComarcas">
		<div class="box-comarca">
			<div class="logo"></div>
			<alert-message/>
			<div class="info active">
				<strong>Escolha as varas:</strong> <br /><br />
				Clique nas comarcas para abrir a listagem de varas.<br /><br />
					<strong>Obs.:</strong> Para cada inscrição, você só poderá selecionar varas de uma mesma comarca.
			</div>

			<div class="comarca-content" v-for="comarca in varas" v-bind:key="comarca.id_comarca">
				<div v-on:click="comarca.ativo = !comarca.ativo" class="comarca-toggle">
					<div class="icon-toggle">
						{{(comarca.ativo) ? '-' : '+'}}
					</div>
					<div class="comarca-nome">
						{{comarca.comarca}}
					</div>
				</div>
				<div :class="{'varas-content':true, active: comarca.ativo}">
					<div v-for="vara in comarca.varas" v-bind:key="vara.id_vara"
						:class="{'vara-box':true,active: vara.ativo}" style="display: grid;grid-template-columns: 1fr;grid-template-rows: 1fr;">												
						<div class="check-option" style="  padding: 20px;display: flex;" v-on:click="validaVaras(vara)">
							<div class="check">

							</div>
							<div class="description">
								{{vara.nome_vara}}
							</div>
						</div>						
						<div class="checkPlantonista" data-v-5d3ff9e0="" style="display: flex; gap: 10px;padding: 20px;background-color: white;">
							<div style="display: flex"><!-- exibe plantonista pra todos -->
								<input type="checkbox" name="plantonista" v-on:input="definePlantonistaNaVara(vara)">
								<span>Plantonista</span>
							</div>
							<!-- {{ plantonista_juri }}-->
							<!-- {{ vara }}  -->
							<div v-if="vara.juri" style="display: flex"> <!-- verifica se exibe ou nao o juri -->
								<input type="checkbox" name="juri" v-on:input="defineJuriNaVara(vara)" :id="'plantonista_juri'+vara.id_vara">
								<span>Júri</span>
								<!-- <p>{{plantonista_juri}}</p> -->
							</div>
						</div>
					</div>
				</div>

			</div>

			<div class="button">
				<button v-on:click="confirmaDados()">Próximo</button>
			</div>

			

		</div>
	</div>
</template>

<script>
import AlertMessage from './components/AlertMessage.vue';
	export default {
		name: "escolhe-varas",
		components: {AlertMessage},
		data() {
			// console.log(this.$store.state.url_api + '/json/')
			return {
				plantonista_padrao: false,
				plantonista_juri: false,
				url_varas: this.$store.state.url_api + '/json/',
				// url_varas: this.$store.state.url_api + '/getVaras.php',

				varas: {

				},
				aviso_teste: false,
				valida_token_url: this.$store.state.url_api + '/verificaTokenValido.php',
				selectedVara : [],
			}
		},
		methods: {
			definePlantonistaNaVara(vara){
				console.log("ESTOU DENTRO DE DEFINEPLANTONISTANAVARA: ");
				console.log(vara);
				if(this.plantonista_padrao){
					vara.plantonista = !vara.plantonista;
				}else{
					vara.plantonista = !vara.plantonista;
				}

				if(vara.plantonista){
					this.$store.commit('set_plantonista', true);
				}else{
					this.$store.commit('set_plantonista', false);
				}
			},

			defineJuriNaVara(vara){
				const juri_vara = document.getElementById("plantonista_juri"+vara.id_vara);
				console.log(juri_vara.checked);
				if(juri_vara.checked){
					vara.juri_selecionado = true;
					this.plantonista_juri = true;
				}else{
					vara.juri_selecionado = false;
				}

				console.log(vara);

				if(vara.juri_selecionado){
					this.$store.commit('set_juri', true);
				}else{
					this.$store.commit('set_juri', false);
				}
			},

			confirmaDados() {
				let comarca = this.varas.filter(
					x => x.id_comarca == this.selectedVara[0].comarca
				)[0];
				// this.selectedVara.forEach((vara)=>{
				// 	console.log(vara);
				// });
				comarca.varas = this.selectedVara;
				let comarcas = [];
				comarcas.push(comarca);

				this.$emit('loader', { active:true });
				this.$store.commit('set_varas', comarcas);

				console.log("PLANTONISTA JURI DE CONTROLE");
				if(this.plantonista_juri){
					console.log("marquei o juri");
					this.$store.commit('set_juri', true);
				}else{
					console.log("NÃO marquei o juri");
					this.$store.commit('set_juri', false);
				}
				// this.$router.push('/escolhe-plantonista');
				this.$router.push('/confirma-dados-inscricao');

				
			},
			validaVaras(vara) {
				const _self = this;
				const ativo = !vara.ativo;

				if(ativo){
					if(_self.selectedVara.length == 0) {
						_self.selectedVara.push(vara);
						vara.ativo = !vara.ativo
					} else {
						console.log(_self.selectedVara);
						if(_self.selectedVara[0].comarca == vara.comarca) {
							_self.selectedVara.push(vara);
							vara.ativo = !vara.ativo
						} else {
							_self.$emit('alert_called', {message: 'Selecionar apenas varas de uma mesma comarca por inscrição.', type: 'danger'});
						}
					}
				} else {
					_self.selectedVara.splice(_self.selectedVara.indexOf(vara), 1);
					vara.ativo = !vara.ativo
				}
			},
			verificaTokenValido(token) {
				return new Promise((resolve, reject) => {
					const Http = new XMLHttpRequest();
					const url = this.valida_token_url;

					let data = new FormData();
					data.append('token', token);

					Http.open("POST", url);
					Http.send(data);
					Http.onreadystatechange = (e) => {
						if (Http.readyState == 4 && Http.status == 200) {
							var res = Http.responseText;
							res = JSON.parse(res);
							if(res.mode) {
								if(res.mode === "sandbox") {
									this.aviso_teste = true;
								}
							}
							if (res.tokenValido) {
								resolve(true);
							} else {
								resolve(false);
							}
						}
					}
				});
			}
		},
		beforeMount() {
			const _self = this;
			this.$emit('loader', { active:true });

			if(sessionStorage.token){
				this.verificaTokenValido(sessionStorage.getItem('token')).then((verificaToken) => {
					if(!verificaToken){
						sessionStorage.removeItem('token');
						sessionStorage.removeItem('varas');
						sessionStorage.removeItem('tipos_escolhidos');
						_self.$emit('alert_called', {message: 'Token de sessão inválido!', type: 'danger'});
						_self.$router.push('/');
					}
				});
				
			}else{
				sessionStorage.removeItem('token');
				sessionStorage.removeItem('varas');
				sessionStorage.removeItem('tipos_escolhidos');
				_self.$emit('alert_called', {message: 'Token de sessão inválido!', type: 'danger'});
				this.$router.push('/');
			}

			_self.$store.commit('get_storage');

			//busca varas com os tipos escolhidos
			const Http = new XMLHttpRequest();
			let url = this.url_varas;

			var data = new FormData();
			data.append('tipos_filter', this.$store.state.tipos_escolhidos);

			const tipoVaraEscolhida = {
				1: 'civil',
				2: 'familia',
				3: 'criminal',
				4: 'juri',
				5: 'eleitoral'
			}
			if(this.$store.state.tipos_escolhidos == ""){
				//ESSA BUDEGA AQUI SÓ SERVE PARA CEJUSC, LEMBRA DISSO ANO QUE VEM QUANDO EU NÃO TIVER AQUI JOANA
				this.$store.state.tipos_escolhidos = [1]
			}
			url += tipoVaraEscolhida[this.$store.state.tipos_escolhidos[0]] + ".json";

			Http.open("POST", url);
			Http.send(data);
			Http.onreadystatechange = (e) => {
				if (Http.readyState == 4 && Http.status == 200) {
					var res = Http.responseText;
					res = JSON.parse(res);
					_self.varas = res;					

					if(sessionStorage.varas) {
						let comarca_sessao = sessionStorage.getItem('varas');

						const comarcas = JSON.parse(comarca_sessao);
						const comarca = comarcas[0];

						const idxComarca = _self.varas.findIndex(vara => vara.id_comarca == comarca.id_comarca);
						_self.selectedVara = comarca.varas;

						this.varas[idxComarca].ativo = true;

						this.varas[idxComarca].varas = this.varas[idxComarca].varas.map(vara => {
							if (this.selectedVara.findIndex(selectVara => vara.id_vara === selectVara.id_vara) !== -1) {
								vara.ativo = true;
							}
							return vara;
						})
					}
					this.$emit('loader', {active: false});
				}
      		}
		}
	};

</script>
<style lang="scss" scoped>
	.escolheComarcas {
		background-image: url('https://advogadosoab.arcoinformatica.com.br/view/public/img/bkg-vitoria.jpg');
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: #ddd;
		display: flex;
		align-items: center;
		justify-content: center;
		background-size: cover;
		// overflow-y: auto;


		@media (max-width: 480px) {
			.box-comarca {
				width: 100% !important;
				height: 100% !important;
				max-height: calc(100% - 60px);
				padding-left: 15px !important;
				padding-right: 15px !important;
			}
		}

		@media (max-width: 768px) {
			.box-comarca {
				width: 60%;
			}
		}

		@media (min-width: 768px) {
			.box-comarca {
				width: 600px;
				max-height: calc(90% - 60px);
			}
		}

		.box-comarca {
			background-color: white;
			padding: 50px;
			box-shadow: 0 0 30px #717171;
			overflow-y: auto;

			&::before {
				content: '';
				position: fixed;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				width: 100%;
				height: 100%;
				background-color: rgba(0, 0, 0, 0.4);
				z-index: -1;
				pointer-events: none;
				margin: 0;
			}

			.logo {
				background-image: url('https://www.oabes.org.br/img/logo/logo.png');
				width: 100%;
				padding-bottom: 15%;
				background-size: contain;
				background-position: left;
				background-repeat: no-repeat;
			}

			.info {
				font-size: 14px;
				text-align: left;
				width: 100%;
				padding-top: 30px;
				display: none;

				.info-senha {
					span {
						color: #737373;
						line-height: 20px;
					}
				}

				&.active {
					display: block;
				}
			}

			.button {
				margin-top: 30px;
				width: 100%;
				display: flex;
				justify-content: flex-end;

				button {
					padding: 10px 30px;
					border: 0;
					background-color: #357a9b;
					color: white;
					font-size: 14px;
					cursor: pointer;

					&:hover {
						background-color: #0a435f;
						transition: .3s all ease-in-out;
					}
				}
			}

			.comarca-content {
				width: 100;
				margin-top: 15px;

			}


			.comarca-content {
				margin-bottom: 15px;
				box-shadow: 0px 2px 2px #d8d8d8;
				background-color: #f8f8f8;
				padding: 10px;

				.comarca-toggle {
					display: flex;
					align-items: center;
					justify-content: flex-start;
					cursor: pointer;
					transition: .3s ease-in-out all;

					&:hover {
						background-color: #f8f8f8;

						.comarca-nome {
							color: #c83827;
						}

					}

					.icon-toggle {
						font-weight: 400;
						font-size: 40px;
						color: #c83827;
						margin-right: 15px;
						transition: 0.3s all ease-in-out;
					}

					.comarca-nome {
						color: #222c45;
						font-weight: bold;
						transition: .3s ease-in-out all;
					}
				}
			}

			.varas-content {
				width: auto;
				padding-left: 30px;
				padding-right: 15px;
				height: 0;
				overflow: hidden;
				opacity: 0;
				pointer-events: none;
				transition: 0.5s all ease-in-out;

				&.active {
					height: auto !important;
					opacity: 1 !important;
					pointer-events: all;
				}

				.vara-box {
					width: auto;
					padding: 10px;
					background-color: #eee;
					border-bottom: solid 1.3px #dadada;
					border-right: solid 1.3px #dadada;
					margin-bottom: 10px;
					cursor: pointer;
					display: flex;
					align-items: center;
					justify-content: flex-start;
					transition: 0.1s ease-in-out all;

					.checkPlantonista {
						display: none !important;
					}

					&.active {
						background-color: #43a047;
						.checkPlantonista {
							display: flex !important;
						}
						.description {
							color: white;
						}

						.check {
							border-color: white;

							&::after {
								content: "\2713";
								position: absolute;
								top: -50%;
								left: 50%;
								bottom: 0;
								right: 0;
								width: 100%;
								height: 100%;
								font-size: 53px;
								display: flex;
								align-items: center;
								justify-content: center;
								color: #183e00;
							}
						}

					}

					.check {
						width: 17px;
						height: 17px;
						border: solid 3px #929292;
						border-radius: 3px;
						margin-right: 15px;
						position: relative;
						flex-basis: auto;

					}

					.description {
						text-transform: uppercase;
						font-weight: bold;
						font-size: 14px;
						color: #565656;
						flex-basis: fit-content;

					}
				}
			}
		}
	}

	.comarca-wrapper {
		float: left;
		width: 100%;
	}
</style>
