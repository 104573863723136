<template>
	<div class="escolheAreas">
		<div class="box-area">
			<div class="logo"></div>
			<!-- <alert-message/> -->
			<div class="info active">
				<strong>Escolha os tipos:</strong> <br /><br />
				Escolha somente uma área de atuação por inscrição:
			</div>
			<div class="area-content">
				<div v-on:click="validaAreas(tipo.id)" v-for="tipo in tipos" v-bind:key="tipo.id" :class="{'area-box':true,active: tipo.ativo}">
					<div class="check">

					</div>
					<div class="description">
						{{tipo.nome}}
					</div>
				</div>
			</div>
			<div v-if="assunto_escolhido != 4" class="button">
				<button v-on:click="verComarcas()" >Ver Comarcas</button>
			</div>

			<div v-else class="button">
				<button v-on:click="verSubsecoes()" >Ver Subseções</button>
			</div>

			<div v-if="aviso_teste" class="alert-teste-content">
				Atenção!
				Este ambiente é apenas de testes, para que os advogados possam se familiarizar com a ferramenta
				de inscrição. <br /><b>Sua inscrição aqui não será válida.</b>
			</div>

		</div>
	</div>
</template>

<script>
import AlertMessage from './components/AlertMessage.vue';
	export default {
		name: "escolhe-areas",
		components: {AlertMessage},
		data(){
			return {
				tipos: [
					    {
							nome : 'Cível',
							id : 1,
							ativo : false
						},
						{
							nome : 'Família',
							id : 2,
							ativo : false
						},
						{
							nome : 'Criminal',
							id : 3,
							ativo : false
						},
						// Para liberar o fluxo eleitoral, retire o comentário abaixo.
						// {
						// 	nome : 'Eleitoral',
						// 	id : 4,
						// 	ativo : false
						// }					
				],
				assunto_escolhido: 0,
				aviso_teste: false,
				valida_token_url: this.$store.state.url_api + '/verificaTokenValido.php',				
			}
		},
		created(){
			this.tipos.forEach((tipo)=>{
				sessionStorage.removeItem('tipos_escolhidos');
			});
		},
		methods:{
			verComarcas(){
				const _self = this;
				this.$emit('loader', { active:true });
				_self.$store.commit('limpa_tipos');
				let selected = false;
				this.tipos.map(function(tipo){
					if(tipo.ativo){
						_self.$store.commit('set_tipo', tipo.id);
						
						selected = true;
					}
					return tipo;
				});
				if(selected){										
					_self.$router.push('/escolhe-varas');
				}else{
					_self.$emit('loader', { active:false });
					_self.$emit('alert_called', {message: 'Escolha ao menos uma opção.', type: 'danger'});
				}
			},
			verSubsecoes(){
				const _self = this;
				this.$emit('loader', { active:true });
				_self.$store.commit('limpa_tipos');
				let selected = false;
				this.tipos.map(function(tipo){
					if(tipo.ativo){
						_self.$store.commit('set_tipo', tipo.id);
						
						selected = true;
					}
					return tipo;
				});
				if(selected){										
					_self.$router.push('/escolhe-subsecoes');
				}else{
					_self.$emit('loader', { active:false });
					_self.$emit('alert_called', {message: 'Escolha ao menos uma opção.', type: 'danger'});
				}
			},
			validaAreas(id) {
				let assunto_escolhido = 0;
				this.tipos.map(function(tipo){ 
					id == 4 ? assunto_escolhido = 4 : assunto_escolhido = 0;
					if(tipo.id == id) {
						tipo.ativo = !tipo.ativo
					} else {
						tipo.ativo = false;
					}
				});
				this.assunto_escolhido = assunto_escolhido;
				console.log(this.assunto_escolhido);
			},
			verificaTokenValido(token) {
				return new Promise((resolve, reject) => {
					const Http = new XMLHttpRequest();
					const url = this.valida_token_url;

					let data = new FormData();
					data.append('token', token);

					Http.open("POST", url);
					Http.send(data);
					Http.onreadystatechange = (e) => {
						if (Http.readyState == 4 && Http.status == 200) {
							var res = Http.responseText;
							res = JSON.parse(res);
							if(res.mode) {
								if(res.mode === "sandbox") {
									this.aviso_teste = true;
								}
							}
							if (res.tokenValido) {
								resolve(true);
							} else {
								resolve(false);
							}
						}
					}
				});
			}
		},
		beforeMount(){
			const _self = this;
			this.$emit('loader', { active:false });

			if(!sessionStorage){
				this.$emit('alert_called', {message: 'Seu navegador não tem as configurações mínimas para esta aplicação. Atualize para um mais novo.', type: 'danger'});
				
				this.$router.push('/');
			}

			if(sessionStorage.token){
				this.verificaTokenValido(sessionStorage.getItem('token')).then((verificaToken) => {
					if(!verificaToken){
						sessionStorage.removeItem('token');
						sessionStorage.removeItem('varas');
						sessionStorage.removeItem('tipos_escolhidos');
						_self.$emit('alert_called', {message: 'Token de sessão inválido!', type: 'danger'});
						_self.$router.push('/');
					}
				});
				
			}else{
				sessionStorage.removeItem('token');
				sessionStorage.removeItem('varas');
				sessionStorage.removeItem('tipos_escolhidos');
				_self.$emit('alert_called', {message: 'Token de sessão inválido!', type: 'danger'});
				this.$router.push('/');
			}

			_self.$store.commit('get_storage');
			if(sessionStorage.tipos_escolhidos){
				let tipos_st = {};
				if(tipos_st = JSON.parse(sessionStorage.getItem('tipos_escolhidos'))){
					tipos_st.forEach(function(id_tipo){
						_self.tipos.forEach(function(tipo){
							if(tipo.id == id_tipo){
								tipo.ativo = true;
							}
						});
					});
				}
			}


		}
	};





</script>
<style lang="scss" scoped>
	.escolheAreas {
		background-image: url('https://advogadosoab.arcoinformatica.com.br/view/public/img/bkg-vitoria.jpg');
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: #ddd;
		display: flex;
		align-items: center;
		justify-content: center;
		background-size: cover;
		overflow-y: auto;


		@media (max-width: 480px) {
			.box-area {
				width: 90%;
			}
		}
		@media (max-width: 768px) {
			.box-area {
				width: 60%;
			}
		}
		@media (min-width: 768px) {
			.box-area {
				width: 440px;
			}
		}
		.box-area{
			background-color: white;
			padding: 50px;
			box-shadow: 0 0 30px #717171;
			&::before{
				content: '';
				position: fixed;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				width: 100%;
				height: 100%;
				background-color: rgba(0,0,0,0.4);
				z-index: -1;
				pointer-events: none;	
				margin: 0;
			}

			.logo{
				background-image: url('https://www.oabes.org.br/img/logo/logo.png');
				width: 100%;
				padding-bottom: 15%;
				background-size: contain;
				background-position: left;
				background-repeat: no-repeat;
			}	
			.info{
				font-size: 14px;
				text-align: left;
				width: 100%;
				padding-top: 30px;
				display: none;
				.info-senha{
					span{
						color: #737373;
    					line-height: 20px;
					}
				}
				&.active{
					display: block;
				}
			}
			.button{
				margin-top: 30px;
				width: 100%;
				display: flex;
				justify-content: flex-end;
				
				button{
					padding: 10px 30px;
					border: 0;
					background-color: #357a9b;
					color: white;
					font-size: 14px;
					cursor: pointer;
					&:hover{
						background-color: #0a435f;
						transition: .3s all ease-in-out;
					}
				}
			}
			.area-content{
				width: 100;
				margin-top: 30px;
				.area-box{
					width: 100%;
					padding: 10px;
					background-color: #eee;
					border-bottom: solid 1.3px #dadada;
					border-right: solid 1.3px #dadada;
					margin-bottom: 15px;
					cursor: pointer;
					display: flex;
					align-items: center;
					justify-content: flex-start;
					transition: 0.1s ease-in-out all;



					&:hover{
						transform: scale(1.02);
					}
					&.active{
						background-color: #43a047;	
						
						.description{
							color: white;
						}
						.check{
							border-color:white;
							&::after{
								content: "\2713";
								position: absolute;
								top: -50%;
    							left: 50%;
								bottom: 0;
								right: 0;
								width: 100%;
								height: 100%;
								font-size: 53px;
								display: flex;
								align-items: center;
								justify-content: center;
								color: #183e00;
							}
						}
							
						}

					.check{
						width: 17px;
						height: 17px;
						border: solid 3px #929292;
						border-radius: 3px;
						margin-right: 15px;
						position: relative;

						
						
					}
					.description{
						text-transform: uppercase;
						font-weight: bold;
						color: #565656;
					}
				}
			}
		}
	}
</style>