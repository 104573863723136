<template>
    <div class="message_alert none">
        <strong>Atenção !</strong> <br /><br />
        Atenção!
        Este ambiente é apenas de testes, para que os advogados possam se familiarizar com a ferramenta
        de inscrição. <br /><b>Sua inscrição aqui não será válida.</b>
    </div>
</template>

<style>
    .message_alert{
        background-color:#ffc3c3; 
        margin: 8px auto; 
        padding: 8px; 
        border:1px solid red;
        border-radius: 4px;
        color:red;
    }
    .none{
        display: none;
    }
</style>

<script>
export default {
    // props: ['message'],
    name: 'AlertMessage'
}
</script>