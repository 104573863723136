<template>
	<div class="escolheComarcas">
		<div v-for="inscricao in inscricoes" class="box-comarca">
			<div class="logo"></div>
			<alert-message/>
			<div class="info active">
				<strong>{{ message }}</strong> <br /><br />
				{{ submessage }}
			</div>

			<div class="box-info">
				<div class="title-area">
					Dados Pessoais
				</div>
				<div class="field" v-for="dado in dados_pessoais">
					<div class="label">
						{{ dado.label }}
					</div>
					<div class="value">
						{{ dado.value }}
					</div>
				</div>
				<div class="aviso">
					Os seus dados pessoais listados acima podem ser alterados. Entre em contato com a OAB-ES

				</div>
			</div>
			<div class="box-info" v-if="nao_se_inscreveu_em_nada">
				<div class="title-area">
					Você ainda não se inscreveu em nenhuma vara ou subseção eleitoral.
				</div>
			</div>
			<!-- {{ inscricao.comarcas.length }} -->
			<div v-if="fez_inscricao_vara" class="box-info">
				<div class="title-area">
					Varas Selecionadas
				</div>
				<div class="field" v-for="comarca in inscricao.comarcas">
					<div class="comarca">
						{{ comarca.comarca_nome }}
					</div>
					<div class="vara" v-for="vara in comarca.varas">
						<div class="ex-left">
							<span>〉<!--<i>{{ vara.id }} - </i>--></span>
						</div>
						<div class="left">
							<!-- {{ vara }} -->
							<strong>Vara: </strong>{{ vara.nome }}
							<p><strong>Plantonista: </strong>{{ vara.quero_ser_plantonista == '1' ? 'SIM' : 'NÃO' }}</p>
							<p><strong>Juri: </strong>{{ vara.quero_ser_juri == '1' ? 'SIM' : 'NÃO' }}</p>
						</div>
						<div class="right">
							<i> {{ vara.timestamp }}</i>
						</div>
					</div>
				</div>
			</div>
			<!-- Para que apareça os dados das inscriçõesde subseção, retire os comentários abaixo:			 -->
			<!--<div v-if="fez_inscricao_eleitoral" class="box-info">
				<div class="title-area">
					Subseções Selecionadas
				</div>
				<div class="field">
					<div class="vara" v-for="sub in subsecoes">
						<div class="ex-left">
							<span>〉--><!--<i>{{ sub.subsecao_id }} - </i>--><!--</span>
						</div>
						<div class="left">
							<p style="text-transform:uppercase">{{ sub.nome_subsecao }}</p>
							<p><strong>plantonista:</strong> {{ sub.quero_ser_plantonista == '1' ? 'SIM' : 'NÃO' }}</p>
						</div>
						<div class="right">
							<i> {{ sub.time_inscricao }}</i>
						</div>
					</div>
				</div>
			</div>-->
			<div class="area-content">
				<div v-for="tipo in termos" v-bind:key="tipo.id" :class="{ 'area-box': true, active: tipo.ativo }">
					<div v-on:click="tipo.ativo = !tipo.ativo" class="check">

					</div>
					<div class="description" v-html="tipo.nome">

					</div>
				</div>
			</div>


			<div class="area-content">
				<div class="button">
					<button v-on:click="back()">Voltar</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import AlertMessage from './components/AlertMessage.vue';
export default {
	name: "confirma-dados-inscricao",
	components: {AlertMessage},
	data() {
		return {
			url_inscrito_eleitoral: this.$store.state.url_api + '/getInfoInscricaoEleitoral.php',
			url_inscrito: this.$store.state.url_api + '/getInfoInscricao.php',
			url_dados_pessoais: this.$store.state.url_api + '/getAdvogado.php',
			valida_token_url: this.$store.state.url_api + '/verificaTokenValido.php',
			varas: {},
			subsecoes: {},
			aviso_teste: false,
			varas_selecionadas: [],
			button_hidden: false,
			message: '',
			submessage: '',
			processoCadastro: false,
			termos: [
			],
			inscricoes: [],
			//dados pessoais
			dados_pessoais: {
				'num_oab': {
					label: 'Número da OAB',
					value: sessionStorage.getItem('num_oab')
				},
				'nome_completo': {
					label: 'Nome',
					value: ''
				},
				'data_nasc': {
					label: 'Data de nascimento',
					value: ''
				},
				'cpf': {
					label: 'CPF',
					value: ''
				},
				'endereco': {
					label: 'Endereço',
					value: ''
				},
				'bairro': {
					label: 'Bairro',
					value: ''
				},
				'cidade': {
					label: 'Cidade',
					value: ''
				},
				'estado': {
					label: 'Estado',
					value: ''
				},
				'telefone_fixo': {
					label: 'Telefone Fixo',
					value: '(28) 9999-999'
				},
				'telefone_celular': {
					label: 'Telefone Celular',
					value: ''
				},
				'email': {
					label: 'E-mail',
					value: ''
				},
				'situacao': {
					label: 'Situação',
					value: ''
				},
				'sit_financeiro': {
					label: 'Situação Financeiro',
					value: ''
				}
			},
		}
	},
	computed:{
		nao_se_inscreveu_em_nada(){
			if(!this.inscricoes[0] && !this.subsecoes[0]){
				return true;
			}else{
				return false;
			}
		},
		fez_inscricao_vara(){
			if(this.inscricoes[0]){
				return true;
			}else{
				return false;
			}
		},
		fez_inscricao_eleitoral(){
			// console.log(this.subsecoes)
			if(this.subsecoes[0]){
				return true;
			}else{
				return false;
			}
		}
	},
	methods: {
		back() {
			const _self = this;
			_self.$router.push('/escolhe-opcao');
		},
		verificaTokenValido(token) {
			return new Promise((resolve, reject) => {
				const Http = new XMLHttpRequest();
				const url = this.valida_token_url;

				let data = new FormData();
				data.append('token', token);

				Http.open("POST", url);
				Http.send(data);
				Http.onreadystatechange = (e) => {
					if (Http.readyState == 4 && Http.status == 200) {
						var res = Http.responseText;
						res = JSON.parse(res);
						if (res.mode) {
							if (res.mode === "sandbox") {
								this.aviso_teste = true;
							}
						}
						if (res.tokenValido) {
							resolve(true);
						} else {
							resolve(false);
						}
					}
				}
			});
		}
	},
	beforeMount() {
		//busca dados da inscrição
		const _self = this;
		this.$emit('loader', { active: true });

		if (sessionStorage.token) {
			this.verificaTokenValido(sessionStorage.getItem('token')).then((verificaToken) => {
				if (!verificaToken) {
					sessionStorage.removeItem('token');
					sessionStorage.removeItem('varas');
					sessionStorage.removeItem('tipos_escolhidos');
					_self.$emit('alert_called', { message: 'Token de sessão inválido!', type: 'danger' });
					_self.$router.push('/');
				}
			});

		} else {
			sessionStorage.removeItem('token');
			sessionStorage.removeItem('varas');
			sessionStorage.removeItem('tipos_escolhidos');
			_self.$emit('alert_called', { message: 'Token de sessão inválido!', type: 'danger' });
			this.$router.push('/');
		}

		//busca dados da inscrição
		_self.$store.commit('get_storage');

		//busca varas com os tipos escolhidos
		const Http = new XMLHttpRequest();
		const url = this.url_inscrito;

		var data = new FormData();
		data.append('token', this.$store.state.token);

		fetch(this.url_inscrito_eleitoral, {
			method: "POST",
			body: data
		}).then(res => res.json())
			.then(data => {
				this.subsecoes = data;
			})

		Http.open("POST", url);
		Http.send(data);
		Http.onreadystatechange = (e) => {
			if (Http.readyState == 4 && Http.status == 200) {
				var res = Http.responseText;
				res = JSON.parse(res);
				if (!res[0]) {
					_self.message = 'Confira seus dados';
					_self.submessage = 'Caso haja alguma divergência, entre em contato com a OAB-ES:';
				} else {
					_self.message = 'Sua inscrição foi realizada!';
					_self.submessage = 'Abaixo seguem os dados da sua inscrição:';
					_self.processoCadastro = true;
				}
				_self.inscricoes = res;
				this.$emit('loader', { active: false });
			}
		}


		//busca dados pessoais
		const Http2 = new XMLHttpRequest();
		const url2 = this.url_dados_pessoais;


		let data2 = new FormData();
		data2.append('token', _self.$store.state.token);

		Http2.open("POST", url2);
		Http2.send(data2);
		Http2.onreadystatechange = (e) => {
			if (Http2.readyState == 4 && Http2.status == 200) {
				var res = Http2.responseText;
				res = JSON.parse(res);

				_self.dados_pessoais.nome_completo.value = res.nome_completo;
				_self.dados_pessoais.num_oab.value = res.oab;
				_self.dados_pessoais.data_nasc.value = res.data_nasc;
				_self.dados_pessoais.cpf.value = res.cpf;
				_self.dados_pessoais.endereco.value = res.endereco;
				_self.dados_pessoais.bairro.value = res.bairro;
				_self.dados_pessoais.cidade.value = res.cidade;
				_self.dados_pessoais.estado.value = res.estado;
				_self.dados_pessoais.telefone_fixo.value = res.telefone_fixo;
				_self.dados_pessoais.telefone_celular.value = res.telefone_celular;
				_self.dados_pessoais.email.value = res.email;
				_self.dados_pessoais.situacao.value = res.situacao;
				_self.dados_pessoais.sit_financeiro.value = res.sit_financeiro;
				_self.dados_pessoais.nome_completo.value = res.nome_completo;
				_self.dados_pessoais.nome_completo.value = res.nome_completo;


				// this.$emit('loader', { active:false });
			}
		}

	}
};





</script>
<style lang="scss" scoped>
.escolheComarcas {
	background-image: url('https://advogadosoab.arcoinformatica.com.br/view/public/img/bkg-vitoria.jpg');
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #ddd;
	display: flex;
	align-items: center;
	justify-content: center;
	background-size: cover;
	// overflow-y: auto;


	@media (max-width: 480px) {
		.box-comarca {
			width: 100% !important;
			height: 100% !important;
			max-height: calc(100% - 60px);
			padding-left: 15px !important;
			padding-right: 15px !important;
		}

		.area-box {
			width: auto !important;

		}

		.value {
			word-break: break-word;
		}
	}

	@media (max-width: 768px) {
		.box-comarca {
			width: 60%;
		}
	}

	@media (min-width: 768px) {
		.box-comarca {
			width: 600px;
			max-height: calc(90% - 60px);
		}
	}

	.box-comarca {
		background-color: white;
		padding: 50px;
		box-shadow: 0 0 30px #717171;
		overflow-y: auto;

		&::before {
			content: '';
			position: fixed;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			width: 100%;
			height: 100%;
			background-color: rgba(0, 0, 0, 0.4);
			z-index: -1;
			pointer-events: none;
			margin: 0;
		}

		.logo {
			background-image: url('https://www.oabes.org.br/img/logo/logo.png');
			width: 100%;
			padding-bottom: 15%;
			background-size: contain;
			background-position: left;
			background-repeat: no-repeat;
		}

		.info {
			font-size: 14px;
			text-align: left;
			width: 100%;
			padding-top: 30px;
			display: none;

			.info-senha {
				span {
					color: #737373;
					line-height: 20px;
				}
			}

			&.active {
				display: block;
			}
		}

		.button {
			margin-top: 30px;
			width: 100%;
			display: flex;
			justify-content: flex-end;

			button {
				padding: 10px 30px;
				border: 0;
				background-color: #357a9b;
				color: white;
				font-size: 14px;
				cursor: pointer;

				&:hover {
					background-color: #0a435f;
					transition: .3s all ease-in-out;
				}
			}
		}
	}
}

.box-info {
	margin-bottom: 30px;
	box-shadow: 0px 2px 2px #d8d8d8;
	background-color: #f8f8f8;
	padding: 10px;
	margin-top: 15px;

	.title-area {
		font-weight: bold;
		color: #616161;
		padding-bottom: 15px;
		font-size: 20px;
	}

	.field {
		margin-bottom: 15px;

		.label {
			font-weight: bold;
			text-transform: uppercase;
			font-size: 14px;
			color: #333;
		}

		.comarca {
			font-weight: bold;
			text-transform: uppercase;
			font-size: 14px;
			color: #333;
			padding-bottom: 10px;
		}

		.vara {
			// padding-left: 15px;
			display: flex;
			// justify-content: flex-start;
			align-items: center;
			padding-bottom: 10px;
			padding-top: 10px;
			border-bottom: solid 1px #dedede;

			i {
				font-style: normal;
				color: #949494;
				font-size: 18px;
			}

			span {
				font-size: 12px;
			}

			.ex-left {
				flex: 0 0 auto;
				white-space: nowrap;
				justify-self: start;
			}

			.left {
				flex: 1 1 auto;
				justify-self: start;
				font-size: 14px;
				padding-left: 10px;
				padding-right: 10px;
			}

			.left strong {
				text-transform: uppercase;
			}

			.right {
				flex: 0 0 auto;
				justify-self: end;
				white-space: nowrap;
			}
		}

		.value {
			padding: 10px;
			background-color: #dcdcdc;
			color: #333;
			margin-top: 3px;
		}
	}
}

.area-content {
	width: 100;
	margin-top: 30px;

	.area-box {
		width: 100%;
		padding: 10px;
		background-color: #eee;
		border-bottom: solid 1.3px #dadada;
		border-right: solid 1.3px #dadada;
		margin-bottom: 15px;
		// cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		transition: 0.1s ease-in-out all;



		&:hover {
			// transform: scale(1.02);
		}

		&.active {
			background-color: #43a047;

			.description {
				color: white;
			}

			.check {
				border-color: white;


				&::after {
					content: "\2713";
					position: absolute;
					top: -50%;
					left: 50%;
					bottom: 0;
					right: 0;
					width: 100%;
					height: 100%;
					font-size: 53px;
					display: flex;
					align-items: center;
					justify-content: center;
					color: #183e00;
					cursor: pointer;
				}
			}

		}

		.check {
			width: 17px;
			height: 17px;
			border: solid 3px #929292;
			border-radius: 3px;
			margin-right: 15px;
			position: relative;
			cursor: pointer;



		}

		.description {
			text-transform: none;
			font-weight: bold;
			color: #565656;
			font-size: 16px;
		}
	}
}

.aviso {
	background-color: #c8eeff;
	padding: 10px;
	text-align: center;
	color: #005b82;
	font-weight: bold;
}
</style>