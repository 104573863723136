<template>
	<div class="escolheSubsecoes">
		<div class="box-comarca">
			<div class="logo"></div>
			<alert-message />
			<div class="info active">
				<strong>Escolha as subseções:</strong> <br /><br />
				Clique nas subseções para abrir a listagem de comarcas.<br /><br />
				<strong>Obs.:</strong> Para cada inscrição, você só poderá selecionar uma comarca de uma mesma subseção.
			</div>
			<!-- subsecao in subsecoes -->
			<!-- era comarca in varas -->
			<div class="varas-content active">
				<div :class="{ 'vara-box': true, active: subsecao.ativo }" v-for="subsecao in subsecoes"
					v-bind:key="subsecao.id_subsecao"
					style="display: grid;grid-template-columns: 1fr;grid-template-rows: 1fr;">
					<div class="check-option" v-on:click="validaSubsecao(subsecao)" style="padding: 20px;display: flex;">
						<div class="check">
						</div>
						<div class="description">
							{{ subsecao.subsecao }}
						</div>
					</div>
					<div v-if="subsecao.ativo" class="checkPlantonista" data-v-5d3ff9e0=""
						style="display: flex; gap: 10px;padding: 20px;background-color: white;">
						<div style="display: flex">
							<input type="checkbox" name="plantonista" v-on:input="definePlantonistaNaSubsecao(subsecao)">
							<span>Plantonista</span>
						</div>
					</div>
				</div>
			</div>

			<div class="button">
				<button v-on:click="confirmaDados()">Próximo</button>
			</div>



		</div>
	</div>
</template>

<script>
import AlertMessage from "./components/AlertMessage.vue";
export default {
	name: "escolhe-subsecoes",
	components: { AlertMessage },
	data() {
		// console.log(this.$store.state.url_api + '/json/')
		return {
			plantonista_juri: false,
			subsecoes: {

			},

			aviso_teste: false,
			valida_token_url: this.$store.state.url_api + '/verificaTokenValido.php',
			selectedSubsecao: []
		}
	},
	created() {
		this.getSubsecoes();
	},
	methods: {
		confirmaDados() {
			console.log("VAMOS LÁ TEMOS QUE CONFIRMAR OS DADOS");
			// console.log(this.selectedSubsecao);
			let subsecao = this.subsecoes.filter(
				item => item.id_subsecao == this.selectedSubsecao[0].subsecao
			)[0];
			subsecao = this.selectedSubsecao;
			// console.log("VAR: ", subsecao);
			// let comarcas = [];
			// comarcas.push(subsecao);

			this.$emit('loader', { active: true });
			this.$store.commit('set_subsecao', subsecao);
			if (this.plantonista_juri) {
				this.$store.commit('set_juri', true);
			} else {
				this.$store.commit('set_juri', false);
			}
			this.$router.push('/confirma-dados-inscricao-eleitoral');
		},
		getSubsecoes() {
			fetch("https://www.oabes.org.br/dativos/api/apiSubsecoes.php", {
				method: 'GET',
				mode: 'cors',
				headers: {
					'Content-Type': 'application/json',
					// Adicione quaisquer outros cabeçalhos necessários aqui
				}
			})
				.then(response => response.json())
				.then(data => {
					this.subsecoes = data;
				});
		},
		definePlantonistaNaSubsecao(subsecao) {
			const plantonista = document.querySelector('input[name="plantonista"]');
			if (plantonista.checked) {
				subsecao.plantonista = true;
			} else {
				subsecao.plantonista = false;
			}
			this.$store.commit("set_subsecao", subsecao);
		},
		validaSubsecao(subsecao) {
			const _self = this;
			const ativo = !subsecao.ativo;

			if (ativo) {
				const num_subsecoes = 1;
				if (_self.selectedSubsecao.length < num_subsecoes) {
					_self.selectedSubsecao.push(subsecao);
					subsecao.ativo = true;
				} else {
					if (_self.selectedSubsecao[0].subsecao == subsecao.subsecao) {
						_self.selectedSubsecao.push(subsecao);
						subsecao.ativo = !subsecao.ativo;
					} else {
						_self.$emit('alert_called', { message: 'Selecione no máximo ' + num_subsecoes + ' opções.', type: 'danger' });
					}
				}
			} else {
				_self.selectedSubsecao.splice(_self.selectedSubsecao.indexOf(subsecao), 1);
				subsecao.ativo = !subsecao.ativo;
			}
		},
		verificaTokenValido(token) {
			return new Promise((resolve, reject) => {
				const Http = new XMLHttpRequest();
				const url = this.valida_token_url;

				let data = new FormData();
				data.append('token', token);

				Http.open("POST", url);
				Http.send(data);
				Http.onreadystatechange = (e) => {
					if (Http.readyState == 4 && Http.status == 200) {
						var res = Http.responseText;
						res = JSON.parse(res);
						if (res.mode) {
							if (res.mode === "sandbox") {
								this.aviso_teste = true;
							}
						}
						if (res.tokenValido) {
							resolve(true);
						} else {
							resolve(false);
						}
					}
				}
			});
		}
	},
	beforeMount() {
		const _self = this;
		this.$emit('loader', { active: true });

		if (sessionStorage.token) {
			this.verificaTokenValido(sessionStorage.getItem('token')).then((verificaToken) => {
				if (!verificaToken) {
					sessionStorage.removeItem('token');
					sessionStorage.removeItem('subsecoes');
					sessionStorage.removeItem('tipos_escolhidos');
					_self.$emit('alert_called', { message: 'Token de sessão inválido!', type: 'danger' });
					_self.$router.push('/');
				}
			});

		} else {
			sessionStorage.removeItem('token');
			sessionStorage.removeItem('subsecoes');
			sessionStorage.removeItem('tipos_escolhidos');
			_self.$emit('alert_called', { message: 'Token de sessão inválido!', type: 'danger' });
			this.$router.push('/');
		}

		this.$emit('loader', { active: false });


	}
};

</script>
<style lang="scss" scoped>
.escolheSubsecoes {
	background-image: url('https://advogadosoab.arcoinformatica.com.br/view/public/img/bkg-vitoria.jpg');
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #ddd;
	display: flex;
	align-items: center;
	justify-content: center;
	background-size: cover;
	// overflow-y: auto;


	@media (max-width: 480px) {
		.box-comarca {
			width: 100% !important;
			height: 100% !important;
			max-height: calc(100% - 60px);
			padding-left: 15px !important;
			padding-right: 15px !important;
		}
	}

	@media (max-width: 768px) {
		.box-comarca {
			width: 60%;
		}
	}

	@media (min-width: 768px) {
		.box-comarca {
			width: 600px;
			max-height: calc(90% - 60px);
		}
	}

	.box-comarca {
		background-color: white;
		padding: 50px;
		box-shadow: 0 0 30px #717171;
		overflow-y: auto;

		&::before {
			content: '';
			position: fixed;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			width: 100%;
			height: 100%;
			background-color: rgba(0, 0, 0, 0.4);
			z-index: -1;
			pointer-events: none;
			margin: 0;
		}

		&.active {
			background-color: #43a047;

			.checkPlantonista {
				display: flex !important;
			}

			.description {
				color: white;
			}

			.check {
				border-color: white;

				&::after {
					content: "\2713";
					position: absolute;
					top: -50%;
					left: 50%;
					bottom: 0;
					right: 0;
					width: 100%;
					height: 100%;
					font-size: 53px;
					display: flex;
					align-items: center;
					justify-content: center;
					color: #183e00;
				}
			}

		}

		.check {
			width: 17px;
			height: 17px;
			border: solid 3px #929292;
			border-radius: 3px;
			margin-right: 15px;
			position: relative;
			flex-basis: auto;
		}

		.logo {
			background-image: url('https://www.oabes.org.br/img/logo/logo.png');
			width: 100%;
			padding-bottom: 15%;
			background-size: contain;
			background-position: left;
			background-repeat: no-repeat;
		}

		.info {
			font-size: 14px;
			text-align: left;
			width: 100%;
			padding-top: 30px;
			display: none;

			.info-senha {
				span {
					color: #737373;
					line-height: 20px;
				}
			}

			&.active {
				display: block;
			}
		}

		.button {
			margin-top: 30px;
			width: 100%;
			display: flex;
			justify-content: flex-end;

			button {
				padding: 10px 30px;
				border: 0;
				background-color: #357a9b;
				color: white;
				font-size: 14px;
				cursor: pointer;

				&:hover {
					background-color: #0a435f;
					transition: .3s all ease-in-out;
				}
			}
		}

		.comarca-content {
			width: 100;
			margin-top: 15px;

		}


		.comarca-content {
			margin-bottom: 15px;
			box-shadow: 0px 2px 2px #d8d8d8;
			background-color: #f8f8f8;
			padding: 10px;

			.comarca-toggle {
				display: flex;
				align-items: center;
				justify-content: flex-start;
				cursor: pointer;
				transition: .3s ease-in-out all;

				&:hover {
					background-color: #f8f8f8;

					.comarca-nome {
						color: #c83827;
					}

				}

				.icon-toggle {
					font-weight: 400;
					font-size: 40px;
					color: #c83827;
					margin-right: 15px;
					transition: 0.3s all ease-in-out;
				}

				.comarca-nome {
					color: #222c45;
					font-weight: bold;
					transition: .3s ease-in-out all;
				}
			}
		}

		.varas-content {
			width: auto;
			padding-left: 30px;
			padding-right: 15px;
			height: 0;
			overflow: hidden;
			opacity: 0;
			pointer-events: none;
			transition: 0.5s all ease-in-out;

			&.active {
				height: auto !important;
				opacity: 1 !important;
				pointer-events: all;
			}

			.vara-box {
				width: auto;
				padding: 10px;
				background-color: #eee;
				border-bottom: solid 1.3px #dadada;
				border-right: solid 1.3px #dadada;
				margin-bottom: 10px;
				cursor: pointer;
				display: flex;
				align-items: center;
				justify-content: flex-start;
				transition: 0.1s ease-in-out all;

				&.active {
					background-color: #43a047;

					.checkPlantonista {
						display: flex !important;
					}

					.description {
						color: white;
					}

					.check {
						border-color: white;

						&::after {
							content: "\2713";
							position: absolute;
							top: -50%;
							left: 50%;
							bottom: 0;
							right: 0;
							width: 100%;
							height: 100%;
							font-size: 53px;
							display: flex;
							align-items: center;
							justify-content: center;
							color: #183e00;
						}
					}

				}

				.check {
					width: 17px;
					height: 17px;
					border: solid 3px #929292;
					border-radius: 3px;
					margin-right: 15px;
					position: relative;
					flex-basis: auto;

				}

				.description {
					text-transform: uppercase;
					font-weight: bold;
					font-size: 14px;
					color: #565656;
					flex-basis: fit-content;

				}


			}
		}
	}
}

.comarca-wrapper {
	float: left;
	width: 100%;
}
</style>
