import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		/**
		 * @TODO 
		 * Change api endpoint
		 */
		url_api: 'https://dativos.oabes.org.br/api',
		token: '',
		tipos_escolhidos: [1],
		varas: {},
		subsecoes: {},
		plantonista: 'false',
		juri:'false'
	},
	mutations: {
		get_storage(state){
			try {
				if(sessionStorage.token){
					state.token = sessionStorage.getItem('token');
				}
				if(sessionStorage.varas){
					state.varas = JSON.parse(sessionStorage.getItem('varas'));
				}
				if(sessionStorage.subsecoes){
					state.subsecoes = JSON.parse(sessionStorage.getItem('subsecoes'));
				}
				if(sessionStorage.tipos_escolhidos){
					state.tipos_escolhidos = JSON.parse(sessionStorage.getItem('tipos_escolhidos'));
				}
				if(sessionStorage.plantonista){
					state.plantonista = sessionStorage.getItem('plantonista');
				}
				if(sessionStorage.juri){
					state.juri = sessionStorage.getItem('juri');
				}
			} catch (error) {
				console.log(error);
			}
			
		},

		set_token(state, new_token){
			state.token = new_token;
			// sessionStorage.setItem("token", state.token);
		},
		set_varas(state, new_varas){
			state.varas = new_varas;
			sessionStorage.setItem("varas", JSON.stringify(state.varas));
		},
		set_subsecao(state, new_subsecoes){
			state.subsecoes = new_subsecoes;
			sessionStorage.setItem("subsecoes", JSON.stringify(state.subsecoes));
		},
		set_tipo(state, id_tipo){
			var tipo_ja_existe = false;
			if(state.tipos_escolhidos.length){
				state.tipos_escolhidos.forEach(function(tipo){
					if(tipo == id_tipo){
						tipo_ja_existe = true;
					}
					return tipo;
				});
			}
			
			if(!tipo_ja_existe){
				state.tipos_escolhidos.push(id_tipo);
				sessionStorage.setItem("tipos_escolhidos", JSON.stringify(state.tipos_escolhidos));
			}			
		},
		set_plantonista(state, new_plantonista){
			state.plantonista = new_plantonista;
			if(state.plantonista == true) {
				state.plantonista = 'true';
			} else {
				state.plantonista = 'false';
			}
			sessionStorage.setItem("plantonista", state.plantonista);
		},
		set_juri(state, new_juri){
			state.juri = new_juri;
			if(state.juri == true) {
				state.juri = 'true';
			} else {
				state.juri = 'false';
			}
			sessionStorage.setItem("juri", state.juri);
		},
		limpa_tipos(state){
			state.tipos_escolhidos = [];
		},
		clear_all(state){
			// state.token = '';
			state.tipos_escolhidos = [];
			state.varas = {};
			state.subsecoes = {};
			sessionStorage.setItem('varas', '');
			sessionStorage.setItem('subsecoes', '');
			sessionStorage.setItem('tipos_escolhidos', '');
			sessionStorage.setItem('plantonista', 'false');
			// sessionStorage.setItem('token', '');
		}
	},
	actions: {

	}
})
